import React, { useState, useEffect } from 'react'
import Input from './../../../input/Input';
import Textarea from './../../../input/Textarea';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Editor from './../../../editor/Editor';
import Button from './../../../button/Button';

let validateNumber = 0;
const ContactForm = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                header: "CONTACT WITH US",
                firstName: "First name",
                lastName: "Last name",
                email: "name@example.com",
                message: "Message",
                regulation: "I also consent to processing of my personal data for the purposes of any future recruitment processes.",
                btnText: "Send",
                // countries: [
                //     { 
                //         title: "Poland"
                //     },
                // ],
                // countriesLabel: "Country / Region",
                regulations: "",
                errorMessage: "",
                successMessage: "",
                sendTo: "",
            }, props.index);
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data || Object.keys(props.data).length == 0) {
                props.onChange({
                    header: "CONTACT WITH US",
                    firstName: "First name",
                    lastName: "Last name",
                    email: "name@example.com",
                    message: "Message",
                    regulation: "I also consent to processing of my personal data for the purposes of any future recruitment processes.",
                    btnText: "Send",
                    // countries: [
                    //     {
                    //         title: "Poland"
                    //     },
                    // ],
                    regulations: "",
                    // countriesLabel: "Country / Region",
                    errorMessage: "",
                    successMessage: "",
                    sendTo: "",
                }, props.index);
            }
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.contact-form-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const updateValue = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        saveData(newData);
    }

    const saveData = (data) => {
        props.onChange({
            ...data
        }, props.index);
    }

    const handleRLDDChange = (result) => {
        if (!result.destination) return;
        const items = Array.from(props.data.countries);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        let copyData = props.data;
        copyData.countries = items;
        saveData(copyData);
    }

    const updateFields = (val, index, label) => {
        let newData = props.data;
        let newCountries = newData.countries;
        newCountries.map((item, i) => i == index ? item[label] = val : item);
        newData.countries = newCountries;
        saveData(newData);
    }

    const addItem = () => {
        let newCountries = props.data.countries ? props.data.countries : [];
        newCountries = [...newCountries, {
            title: '',
        }]
        let copyData = props.data;
        copyData.countries = newCountries;
        saveData(copyData);
    }

    const removeItem = (index) => {
        let newCountries = props.data.countries.filter((item, i) => i == index ? false : item);
        let copyData = props.data;
        copyData.countries = newCountries;
        saveData(copyData);
    }

    const handleKeyDown = (e, functionName, functionValue = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue)
        }
    }
    
    return (
        <div className={error ? `section-wrapper contact-form contact-form-${props.index} error-section` : `section-wrapper contact-form contact-form-${props.index}`}>
            <div className="section-items">
                <div className="row-section">
                    <Input  required={ false }
                        setFunction={ val => updateValue(val, "header") }
                        value={props.data.header}
                        label={ 'Nagłówek' }
                        regExp={ /.*/ }
                        minChar={ 3 } 
                        forceValidate={ forceValidate } 
                    />
                </div>

                <div className="row-section">
                    <Input  required={ true }
                        setFunction={ val => updateValue(val, "firstName") }
                        value={props.data.firstName}
                        label={ 'Placeholder imię' }
                        regExp={ /.*/ }
                        minChar={ 3 } 
                        forceValidate={ forceValidate } 
                    />
                </div>

                <div className="row-section">
                    <Input  required={ true }
                        setFunction={ val => updateValue(val, "lastName") }
                        value={props.data.lastName}
                        label={ 'Placeholder nazwisko' }
                        regExp={ /.*/ }
                        minChar={ 3 } 
                        forceValidate={ forceValidate } 
                    />
                </div>

                <div className="row-section">
                    <Input  required={ true }
                        setFunction={ val => updateValue(val, "email") }
                        value={props.data.email}
                        label={ 'Placeholder email' }
                        regExp={ /.*/ }
                        minChar={ 3 } 
                        forceValidate={ forceValidate }  
                    />
                </div>

                <div className="row-section">
                    <Input  required={ true }
                        setFunction={ val => updateValue(val, "message") }
                        value={props.data.message}
                        label={ 'Placeholder wiadomość' }
                        regExp={ /.*/ }
                        minChar={ 3 } 
                        forceValidate={ forceValidate } 
                    />
                </div>

                <div className="row-section">
                    {/* <Textarea  required={ true }
                        setFunction={ val => updateValue(val, 'regulation') }
                        value={props.data.regulation}
                        label={ 'Zgoda' }
                    /> */}
                    <Editor label={"Zgoda"} required={false} forceValidate={ forceValidate } setFunction={ val => updateValue(val, "regulation") } value={props.data.regulation} />
                </div>

                <div className="row-section">
                    <Input  required={ true }
                        setFunction={ val => updateValue(val, "btnText") }
                        value={props.data.btnText}
                        label={ 'Tekst na przycisku' }
                        regExp={ /.*/ }
                        minChar={ 3 } 
                        forceValidate={ forceValidate } 
                    />
                </div>

                <div className="row-section">
                    <Editor required={true} label="Informacja zwrotna gdy wiadomości nie uda się wysłać" forceValidate={ forceValidate } setFunction={ val => updateValue(val, "errorMessage") } value={props.data.errorMessage} />
                </div>

                <div className="row-section">
                    <Editor required={true} label="Informacja zwrotna gdy wiadomość zostanie wysłana" forceValidate={ forceValidate } setFunction={ val => updateValue(val, "successMessage") } value={props.data.successMessage} />
                </div>
                <div className="row-section">
                    <Input  required={ false }
                        setFunction={ val => updateValue(val, "sendTo") }
                        value={props.data.sendTo}
                        label={ 'Do kogo wysłać email (większa ilość po przecinku)' }
                        regExp={ /.*/ }
                        forceValidate={ forceValidate } 
                    />
                </div>
            </div>
        </div>
    );
}

export default ContactForm;


